<template>
  <div style="width: 100%">
    <v-col cols="12" class="mt-0 pb-0">
      <p class="red--text m-0 mb-4">* Hãy nhập các đáp án</p>
      <template v-for="(option, i) in optionAnswerSingleChoice">
        <div
            :key="i"
            style="display: flex"
            class=""
            v-bind:class="{'mb-2': hasConfigPhonetic }"
        >
          <div :style="[hasConfigPhonetic ? {'width': '60%'} : {'width': '100%'}]" style="display: flex">
            <p class="text-h5 mt-3 mr-1" style="color: #6495ed">
              {{ i | getAlphabetCharacterFromIndex }}.
            </p>
            <v-text-field
              :ref="'single-choice-'+i"
              outlined dense
              class="mt-0 mr-1"
              :label="'Option' + (i + 1)"
              v-model="option.value"
              @input="changeValueSingleChoice"
            ></v-text-field>

            <v-btn
                v-if="hasConfigPhonetic"
                outlined color="#039BE5"
                class="mt-0"
                @click="replacePinyinByTextSelectedViaInputOptionRef('single-choice-'+i, i)"
            >
              Thêm phiên âm
            </v-btn>

            <v-btn
                outlined color="grey"
                class="mt-0"
                @click="deleteOptionSingleChoice(i)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
          <div style="width: 40%; border: 1px solid grey" class="ml-1 rounded" v-if="hasConfigPhonetic">
            <p class="subtitle-1 mb-0 border-bottom pl-1" style="background-color: #8080806e">Preview</p>
            <div
                :class="{ 'container-pinyin-preview' : hasConfigPhonetic }"
                class="rounded px-1 py-2"
                v-html="option.value">
            </div>
          </div>
        </div>
      </template>
    </v-col>
    <v-col cols="3" class="pt-0">
      <v-btn large @click="addOptionSingleChoice">
        <v-icon>mdi-plus</v-icon> Option
      </v-btn>
    </v-col>
    <v-col cols="12" class="mt-0 pb-0">
      <p class="red--text m-0">* Hãy chọn 1 đáp án đúng</p>
      <p class="red--text m-0">
        * Hãy di chuyển các mục (A, B, C, ...) đi chuyển thứ tự theo ý muốn và
        chọn lại đáp án
      </p>
      <v-radio-group v-model="correctSingleChoice">
        <draggable :list="optionAnswerSingleChoice" @change="resetCorrect">
          <template v-for="(option, i) in optionAnswerSingleChoice">
            <div :key="i" class="d-flex">
              <p class="text-h5 mt-4 mr-2" style="color: #6495ed">
                {{ i | getAlphabetCharacterFromIndex }}.
              </p>
              <v-radio :value="option.value" class="pt-0">
                <template v-slot:label>
<!--                  <v-text-field-->
<!--                    :label="'Option' + (i + 1)"-->
<!--                    v-model="option.value"-->
<!--                    class="pt-0 mt-0"-->
<!--                    readonly-->
<!--                  ></v-text-field>-->
                  <div style="width: 500px; border: 1px solid grey" class="ml-1 rounded">
                    <p class="subtitle-1 mb-0 border-bottom pl-1" style="background-color: #8080806e">Option {{i +1}}</p>
                    <div
                        class="rounded px-1 py-2"
                        v-html="option.value">
                    </div>
                  </div>
                </template>
              </v-radio>
            </div>
          </template>
        </draggable>
      </v-radio-group>
    </v-col>
  </div>
</template>5

<script>
import draggable from "vuedraggable";
import phoneticSupport from "../Support/Question/phoneticSupport";
export default {
  name: "SingleChoice",
  mixins: [
      phoneticSupport,
  ],
  components: {
    draggable,
  },
  props: {
    option: {
      type: Array,
      default: () => [],
    },
    correct: {
      type: String,
      default: null,
    },
    hasConfigPhonetic: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {};
  },
  computed: {
    optionAnswerSingleChoice: {
      get() {
        return this.option;
      },
      set(value) {
        this.$emit("setOptionAnswer", value);
      },
    },
    correctSingleChoice: {
      get() {
        return this.correct;
      },
      set(value) {
        this.$emit("setCorrectAnswer", value);
      },
    },
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
  },
  methods: {
    changeValueSingleChoice() {
      this.correctSingleChoice = null;
    },
    addOptionSingleChoice() {
      let data = { value: "" };
      this.optionAnswerSingleChoice.push(data);
    },
    deleteOptionSingleChoice(i) {
      if (this.optionAnswerSingleChoice.length <= 1) {
        this.$toasted.error("Phải có tối thiểu 1 option !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      this.optionAnswerSingleChoice.splice(i, 1);
    },
    resetCorrect() {
      this.correctSingleChoice = null;
    },
    async replacePinyinByTextSelectedViaInputOptionRef(inputRef = '', indexOption) {
      if (!inputRef) {
        this.messError('Có lỗi ! Hãy báo bên kỹ thuật');
        return;
      }
      let refOption = this.$refs[inputRef][0].$refs;
      let input = refOption.input;

      const textSelected = input.value.substring(
          input.selectionStart,
          input.selectionEnd,
      );

      if (!textSelected) {
        this.messError('Chưa chọn vùng text để lấy pinyin');
        return;
      }

      const starSto = input.value.substring(0, input.selectionStart);
      const endSto = input.value.substring(input.selectionEnd, input.value.length);

      const dataPinyin = await this.getDataPinyinByTextSelected(textSelected);
      if (!dataPinyin) return;

      this.optionAnswerSingleChoice[indexOption].value =
          starSto +
          " " + dataPinyin + " " +
          endSto
      ;

      setTimeout(() => {
        input.focus();
        input.selectionStart = starSto.length + dataPinyin.length +2;
        input.selectionEnd = starSto.length + dataPinyin.length +2;
      }, 10);

    },
  },
};
</script>

<style scoped>
.container-pinyin-preview {
  height: auto;
  min-height: 40px;
  font-size: 28px;
  line-height: normal;
}
</style>
